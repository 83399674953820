@charset "utf-8";
@import "./variables.scss";

// @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,900");
@import url('https://fonts.googleapis.com/css?family=Libre+Caslon+Text:400,400i,700|Lobster+Two:700|Oswald:300,500,700&display=swap');

// font-family: 'Oswald', sans-serif;  font-weight: 300,500,700;font-size: .7em;
// font-family: 'Libre Caslon Text', serif;  font-weight:  400,400i,700;font-size: .7em;
// font-family: 'Lobster Two', cursive; font-weight: 700;font-size: .7em;

body {
  // display: flex;
  // flex: 0 0 100%; /* Let it fill the entire space horizontally */  font-family: "Oswald", sans-serif;
  width: 100%;
  height: 100%;
  background: $menublue--50 url('../assets/images/noise.png');
  // color: $black-80;
}

html,
body,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: "Oswald", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  color: $black-80;
}
h3{
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  font-size: 1.2em;
}
p {
  font-family: 'Libre Caslon Text', serif;
  font-weight: 400;
}
p > span{
  font-family: 'Libre Caslon Text', serif;
  font-weight: 400i;
}

ul {
  list-style: none; }

button,
input,
select,
textarea {
  margin: 0; }

html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit;
}

a{
  color: $black-40;
  text-decoration: none;
  &:hover, &:active{
    color: $black-20;
  }
}

.App {
  display: flex;
  flex: 0 0 100%; /* Let it fill the entire space horizontally */
  min-height: 100vh;
  flex-direction: column;
  align-items: stretch;

  .container-what-container{
    flex: 0 0 100%; /* Let it fill the entire space horizontally */
    align-content: stretch;
    max-width: 100%;
    }
}

@import "./article.scss";
@import "./bubbles.scss";
@import "./footer.scss";
@import "./header.scss";
@import "./menu.scss";
@import "./mixins.scss";
@import "./patterns.scss";

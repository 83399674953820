@import "./variables.scss";

.menu-container{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  /* This aligns items to the end line on main-axis */
  // justify-content: flex-end;
  // margin-left: 20px;
  // background-color: pink;

  .top-menu{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    u {
      padding: 0;
      flex: 0 0 100%;

      a, li{
        list-style: none;
        &:hover,
        &:active{
          transform: all .5s ease-out;
        }
      }
    }

    .menu-box-large{
      color: $black-40;
      font-weight: 500;
      font-family: Oswald;
      font-size: .9em;
            width: 145px;
      height: 32px;
      background-color:$menublue;// #113c6f;
      border-top: 2px solid $borderblue;
      border-left: 2px solid $borderblue;
      border-right: 2px solid $borderblue;
      border-bottom: 2px solid $borderblue;
      border-radius: 0 0 10px 3px;
      margin-right: 7px;
      margin-bottom: 7px;
      cursor: pointer;
      -webkit-filter: drop-shadow(6px 8px 6px rgba(0,0,0,0.4));
      filter: drop-shadow(6px 8px 6px rgba(0,0,0,0.4));

      // background-position-y: -16px;
      // transition: translateY(15px);
        // img {src='../../public/img/image-name-here'}

      &:hover,
      &:active{
        color: $black-20;
        transform: all .5s ease-out;
        border-color: $orange;
        -webkit-filter: drop-shadow(4px 8px 6px rgba(0,0,0,0.6));
        filter: drop-shadow(4px 8px 6px rgba(0,0,0,0.6));
      }

    }

  }
  .submenu-container{
    margin-top: 52px;

    .menu-box-small{
      // color: pink;
      width: 175px;
      height: 30px;
      border-radius: 2px 2px 9px 2px;
      background-color: $menublue;
      border: 1px solid $borderblue; //#C8E9D9;
      margin-top: 2px;
      margin-bottom: 2px;
      align-items: center;
      padding-left: 5px;

      transition: border(1px solid $orange);

      &:hover,
      &:active {
        transform: all .9s ease;
        background-color:$submenublue -15%;
        border-color: $orange;

        font-weight: bold;
      }
    }
  }

  .orange-circle{
    width: 110px;
    height: 110px;
    color: white;
    border: 3px solid $orange;
    border-radius: 50%;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    z-index: 20;
  }

  .navbar-icon{
    position: relative;
    width: 70px;
    height: 70px;
    margin: auto;
  }

    /* Medium screens */
  @media all and (max-width: 800px) {
    .navigation {
      /* When on medium sized screens, we center it by evenly distributing empty space around items */
      justify-content: space-around;
    }
  }

  /* Small screens */
  @media all and (max-width: 500px) {
    .navigation {
      /* On small screens, we are no longer using row direction but column */
      flex-direction: column;
    }
  }

}
@charset "utf-8";
@import "./variables.scss";


.site-footer{
  display: flex;
  flex-shrink: 0;
  // flex-direction: row;
  // flex: 0 0 100%;
  background-color:$blgrey-lighter--80;
  height: 45px;

  .footer-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 100%;
    // align-content: space-between;

    .footer-logo{
      display: flex;
      align-self: flex-start;
      margin-left: 5em;
      width: 180px;
      height: auto;
    }

    .footer-school{
      display: flex;
      align-items: flex-end;
      margin-right: 10em;
      margin-left: 2em;

      .school-name,
      .dept-name{
        align-self: flex-end;
        font-size: .9rem;
        line-height: 1rem;
      }

    }
  }
}
$badass: #BADA55;
$darkblue: #282c34;

//used colors for ECE
$orange: #f99e39;
$menublue: #113c6f;
$menublue--10: rgba(17, 60, 111, .1);
$menublue--20: rgba(17, 60, 111, .2);
$menublue--30: rgba(17, 60, 111, .3);
$menublue--50: rgba(17, 60, 111, .5);
$menublue--70: rgba(17, 60, 111, .7);
$submenublue: #4976AB;
$borderblue: rgba(116, 141, 181, 1.00);
$bubbles: #b7ad55;
$brown: #757763;
$greyish-brown:  #878972;
$black-95: rgba(20,21,19,1.0);
$black-80: #2b2b2b;
$black-70: #515151;
$black-60: #646464;
$greydark: #777971;
$black-40: #949494;
$black-20: #cfcfcf;
$black-10: #EFF0EB;
$greylight: #d8d9d1;
$greyverylight: #e8e7e7;

$contentarea: #c9c9c2;
$pink: pink;

// Set your brand colors
$beige-light: rgba(208, 209, 205, .5);
$beige-lighter: rgba(239, 240, 235, .5);

// Update Bulma's global variables
$family-sans-serif: "Oswald", sans-serif;
$grey-light: $beige-light;
//Update some of Bulma's component variables
$blgrey-lighter--50:	rgba(	219, 219, 219, .5);
$blgrey-lighter--80:	rgba(	219, 219, 219, .8);


  // -webkit-filter: grayscale(70%); filter: grayscale(70%);
  // -webkit-filter: brightness(70%); filter: brightness(70%);
  // -webkit-filter: drop-shadow(6px 8px 6px rgba(0,0,0,0.4)));; filter: drop-shadow(6px 8px 6px rgba(0,0,0,0.4)));
@charset "utf-8";
@import "./variables.scss";
// @import './patterns';

.content-area{
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;

  .page{
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    // -webkit-filter: drop-shadow(6px 8px 6px rgba(0,0,0,0.4));
    // filter: drop-shadow(6px 8px 6px rgba(0,0,0,0.4));
  }
}

.bubbles{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // background: $menublue--50;
  align-items: flex-start;
  align-items: center;
  padding-bottom: 1em;
}

.bubble-link{
  display: flex;
  justify-content: center;
  .active{
    img {
      -webkit-filter: grayscale(0.2);
      filter: grayscale(0.2);
    }
  }
  &:active,
  &:hover{
    color: $menublue--50;
    img.animated {-webkit-animation: filter-animation 2s ;}
    @-webkit-keyframes filter-animation {
      0% {-webkit-filter: greyscale(0) saturate(100%);}
      50% {-webkit-filter: greyscale(0) saturate(150%);}
      100% {-webkit-filter: greyscale(40%) saturate(150%);}
    }
    // filter: blur(0.7px);
  }
}


.bubble {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // align-items: flex-start;
  color: $black-60;
  max-width: 160px;
  // border-radius: 50%;
  &:focus,
  &:hover,
  &:active {
    color: $submenublue;
    box-shadow: none;
  }

  .bubble-cropper{
    position: relative;
    width: 90px;
    height: 90px;
    overflow: hidden;
    border-radius: 50%;
    margin: 10px;
    border: 2px solid $black-40;
    background: $black-20;

    &:focus,
    &:hover,
    &:active {
      border: 2px solid $submenublue;
      filter: greysacle(65%);
      color: $submenublue;
    }

    .bubble-image{
      display: flex;
      margin: 0 auto;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 1s ease;

      &:focus,
      &:hover,
      &:active {
        width: 110%;
        -webkit-filter: grayscale(80%);filter: grayscale(80%);
        -webkit-filter: brightness(70%);filter: brightness(70%);
        // border: 2px solid $submenublue;
      }
    }
  }

  .bubble-profTitle{
    font-family: 'Lobster Two', cursive;
    font-weight: 700;
    font-size: 0.9em;
  }

  .bubble-profName{
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 1em;
  }

  .bubble-headline{
    font-family: 'Oswald', sans-serif;
    font-weight: 700;
    font-size: .9em;
    flex-wrap: wrap;
   }
  // .bubble-description{}
}



// font-family: 'Open Sans', sans-serif;  font-weight: 400,600,700,900;font-size: .7em;
// font-family: 'Oswald', sans-serif;  font-weight: 300,500,700;font-size: .7em;
// font-family: 'Libre Caslon Text', serif;  font-weight:  400,400i,700;font-size: .7em;
// font-family: 'Lobster Two', cursive; font-weight: 700;font-size: .7em;




// @import './patterns.scss';
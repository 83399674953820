@charset "utf-8";
@import "./variables.scss";

.header {
  display: flex;
  // background-color: $brown;
  flex-direction: row;
  justify-content: flex-start;
  height: 3.3rem;
  flex: 0 1 100%;
  align-items: flex-start;
  align-content: space-between;

  .logos{
    margin-left: 3rem;
    margin-right: 3rem;
    flex: none;

    .logo-image{
      width: 80px;
      height: auto;
      margin-top: 5px;
      // margin-left: 30px;
    }

    .transition-header{
      // flex: 1 1 100%;
      // flex-direction: column;
      display: inline-block;
      height: 200px;
      margin-top: 50px;
      margin-bottom: 50px;
      border: 20px dotted red;
      // background: $menublue--50 ;
      background: $blgrey-lighter--50;
      //url('../assets/images/noise.png');
      align-items: flex-start;
      margin-top: 140px;
      // background: linear-gradient(90deg, rgba(117,119,96,1) 0%,  rgba(117,119,76,0) 100%);
      // background: linear-gradient(90deg, purple 0%,  orange 100%);
    }
}


.content-area{

}

.bubbles-submenu{

}

@import "./menu";
}

@charset "utf-8";
@import "./variables.scss";

img.articleImageRight.madni{
  object-fit: contain;
  object-position: -10% 50%;
  height: 112%;
  max-width: 100%;
  justify-content: center;
  margin: 0 20px;
}

img.articleImageLeft.none{
  display: none;
  max-height: 10px;
}
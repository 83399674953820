@charset "utf-8";
@import "./variables.scss";

.grid-layout {
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(325px, 1fr)) ;
  grid-template-columns: 1fr 1fr ;
  // grid-template-rows: repeat(2, 2fr);
  grid-gap: 2em;
  padding: 1em;
  width: 100%;

  .full {
    grid-column: span 2;
    // justify-self: center;
    align-content: start;
    margin-bottom: 1em;
  }

  h3.page-title{
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 1.8em;
    color: $black-20;
    text-indent: 0;
    text-align: left;
    margin-bottom: 1em;
  }

  h4.page-subtitle{
    font-family: 'Libre Caslon Text', serif;
    font-weight: 400;
    font-size: 1em;
    text-align: justify;
    text-justify: inter-word;
    text-indent: 0;
    // text-indent: 0;
  }

  .half {
    grid-column: span 1;
    grid-template-rows: 1fr 1fr auto 1fr;
    align-content: start;
    justify-self: center;
    grid-row: 1fr 1fr minmax(125px, 2fr) 1fr;
  }

  .page-award{
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 1.3em;
    margin-bottom: 0.5em;
    text-indent: 0;
    text-align: left;
  }
  .page-name{
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 1.3em;
    margin-bottom: 0.5em;
    text-indent: 0;
  }
  .page-text{
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 1em;
    margin-bottom: 1em;
    text-indent: 0;
    text-align:justify;
    text-justify: inter-word;

    span.first-letter{
      font-family: 'Lobster Two', cursive, "Times", "Times New Roman";
      // initial-letter: 3 2;
      font-size:2.5em;
      float: left;
      margin: -0.2em .3em 0 0;
      color: $menublue;
      text-indent: 0;
    }
  }

  .page-caption{
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 1.5em;
    text-indent: 0;
    text-align: left;
  }

  .page-img{
    grid-template-columns: 1fr;
    justify-content: start;
    width: 100%;
    text-indent: 0;

    img{
      object-fit: cover;
      object-position: 50% 50%;
      overflow: hidden;
      transition: all 0.3s linear;
      justify-self: center;
      max-width: 100%;
      max-height: 456px;
      height: auto
    }
      // img:hover {
      //   -webkit-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -10px 20px;
      //   -moz-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -10px 20px;
      //   -o-box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -10px 20px;
      //   box-shadow:rgba(0,0,0,0.8) 0px 5px 15px, inset rgba(0,0,0,0.15) 0px -10px 20px;
      // }

    .spacer{
      margin: 1em 0;
    }
  }

  //page speciffic css
  .hkn{
    margin-bottom: 1.5em;
  }

  img.cpu {
    width: 100%;
    max-height: 200px;
    float: left;
    object-fit: cover;
    object-position: 50% 45%;
    overflow: hidden;
    margin-bottom: 1.5em;
  }

  .prof-pics{
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(150px, 4fr));
    grid-gap: 2em;
    padding: 0;
    text-indent: 0;
    margin-top: -0.2em;

    img.fac{
      width: 180px;
      height: 250px;
      // min-height: 260px;
      object-fit: cover;
      object-position: 50% 50%;
      overflow: hidden;
    }
  }

  .researchcenters {
    display: grid;
    // background: $greydark;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(220px, 4fr));
    grid-gap: 2.2em;


    .page-award.ctr{
      max-width: 250px;
      font-size: 1.1em;
      // height: auto;

      img.center{
        background: white;
        width: 250px;
        height: 250px;
        // min-height: 260px;
        object-fit: cover;
        object-position: 50% 50%;
        // overflow: hidden;
      }
    }
  }
  .page-caption.faculty-caption{
    margin-bottom: 2em;
  }



}

@media only screen and (max-width: 900px) {
  .grid-layout {
    display: block;
  }
  .full {
    grid-column: span 1;
  }
  img.fac{
    width: 146px;
    height: 203px;
  }
}

@import './transfers.scss';